.App {
  background-color: #dd5119;
  height: 100vh;
}

.logo {
  height: 11vmin;
  color: #010107;
  position: absolute;
  padding-left: 8%;
  margin-top: 4%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fefee2;
}

.videoTag {
  position: absolute;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
}

.flex-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  height: 100vh;
  bottom: 0;
}

.flex-item {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

  margin: 5%;
  line-height: 20px;
  color: #fefee2;
  align-self: flex-end;
  height: auto;
}

#mail {
  text-decoration: none;
  transform: translate(0px, 3vh);
  font-size: calc(10px + 2vmin);
}

#cs {
  position: absolute;
  transform: translate(0px, 80vh);
}

#social {
  color: #fefee2;
}
